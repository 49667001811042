//Theme Toggler
function setDarkMode() {
  document.documentElement.classList.remove("light");
  document.documentElement.classList.add("dark");

  themeBttnState.checked = false;
  localStorage.setItem("currentMode", "dark");
}
function setLightMode() {
  document.documentElement.classList.remove("dark");
  document.documentElement.classList.add("light");

  themeBttnState.checked = true;
  localStorage.setItem("currentMode", "light");
}

// Set initial Mode

const themeBttnState = document.querySelector("#themeToggler");
let initialMode = "light";
let color_preference = window.matchMedia("(prefers-color-scheme: dark)");
if (color_preference.matches) {
  initialMode = "dark";
  console.log("Sys Dark Mode");
  setDarkMode();
}

if (localStorage.getItem("currentMode") == null) {
  localStorage.setItem("currentMode", initialMode);
} else {
  let currentMode = localStorage.getItem("currentMode");
  if (currentMode == "dark") {
    setDarkMode();
  } else if (currentMode == "light") {
    setLightMode();
  }
}

// Toggle Button
themeBttnState.addEventListener("change", (e) => {
  if (e.target.checked === true) {
    setLightMode();
    console.log("Switch Light");
  }

  if (e.target.checked === false) {
    setDarkMode();
    console.log("Switch Dark");
  }
});
